import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { nextTick } from "vue";
const user = JSON.parse(JwtService.getUser());
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/clients",
    component: () => import("@/layout/Layout.vue"),
    children: [
      
     

      {
        path: "/account-settings",
        name: "account-settings",
        component: () => import("@/views/crafted/account/Settings.vue"),
      },

      {
        path: "/mobile-settings",
        
        name: "mobile-settings",
        component: () => import("@/views/crafted/settings/MobileAppSettings.vue"),
      },

      {
        path: "/packages",
        name: "package-groups",
        component: () => import("@/views/crafted/settings/definations/PackageGroupList.vue"),
      },

      {
        path: "/categories",
        name: "categories",
        component: () => import("@/views/crafted/settings/definations/CategoryList.vue"),
      },

      {
        path: "/packages-by-group/:package_id",
        name: "packages",
        component: () => import("@/views/crafted/settings/definations/PackagesList.vue"),
      },

      
      {
        path: "/clients",
        name: "clients",
        component: () => import("@/views/manage/ClientsList.vue"),
      },

      {
        path: "/user-groups",
        name: "user-groups",
        component: () => import("@/views/manage/UserGroupsList.vue"),
      },

      {
        path: "/consultant-types",
        name: "consultant-types",
        component: () => import("@/views/crafted/settings/definations/ConsultantTypes.vue"),
      },
      {
        path: "/faq",
        name: "faq",
        component: () => import("@/views/crafted/settings/definations/FaqList.vue"),
      },
      {
        path: "/contracts",
        name: "contracts",
        component: () => import("@/views/crafted/settings/definations/ContractTypes.vue"),
      },

      {
        path: "/contract-versions",
        name: "contract-versions",
        props: true,
        component: () => import("@/views/crafted/settings/definations/ContractVersions.vue"),
      },

      {
        path: "/onboardings",
        name: "onboardings",
        component: () => import("@/views/crafted/settings/definations/Onboardings.vue"),
      },

      {
        path: "/mobile-slider",
        name: "mobile-slider",
        component: () => import("@/views/crafted/settings/definations/MobileSlider.vue"),
      },

      
     
      {
        path: "/client/:user_id/",
        name: "profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        children: [
          
          {
            path: "calls",
            name: "profile-calls",
            component: () =>
              import("@/views/crafted/pages/profile/Calls.vue"),
          },
          {
            path: "activities",
            name: "profile-activities",
            component: () =>
              import("@/views/crafted/pages/profile/Activities.vue"),
          },
          {
            path: "notes",
            name: "profile-notes",
            component: () =>
              import("@/views/crafted/pages/profile/Notes.vue"),
          },
          {
            path: "files",
            name: "profile-files",
            component: () =>
              import("@/views/crafted/pages/profile/Files.vue"),
          },
          {
            path: "purchases",
            name: "profile-purchases",
            component: () =>
              import("@/views/crafted/pages/profile/Purchases.vue"),
          },
          {
            path: "packages",
            name: "profile-packages",
            component: () =>
              import("@/views/crafted/pages/profile/Packages.vue"),
          },
          {
            path: "group-logs",
            name: "profile-group-logs",
            component: () =>
              import("@/views/crafted/pages/profile/GroupLogs.vue"),
          },
          {
            path: "settings",
            name: "profile-settings",
            component: () =>
              import("@/views/crafted/pages/profile/Settings.vue"),
          },
         
        ],
      },

      {
        path: "/consultants/:consultant_type",
        name: "consultants",
        component: () => import("@/views/manage/ConsultantList.vue"),
      },
      {
        path: "/system-users",
        name: "system-users",
        component: () => import("@/views/manage/SystemUsersList.vue"),
      },
      {
        path: "/consultants/create",
        name: "consultantCreate",
        component: () => import("@/views/manage/CreateConsultant.vue"),
      },

      {
        path: "/system-users/create",
        name: "systemUsersCreate",
        component: () => import("@/views/manage/CreateSystemUser.vue"),
      },

      {
        path: "/consultant/:user_id/",
        beforeEnter:function(){
          
          if(user.lUserType!='Admin'){
            window.location.href='/';
          }

          
        },
        name: "consultant",
        component: () => import("@/components/page-layouts/Consultant.vue"),
        children: [
          {
            path: "settings",
            name: "consultant-settings",
            component: () =>
              import("@/views/crafted/pages/consultant/Settings.vue"),
          },
          {
            path: "calendar",
            name: "consultant-calendar",
            component: () =>
              import("@/views/crafted/pages/consultant/Calendar.vue"),
          },
          {
            path: "files",
            name: "consultant-files",
            component: () =>
              import("@/views/crafted/pages/consultant/Files.vue"),
          },
          {
            path: "pricing",
            name: "consultant-pricing",
            component: () =>
              import("@/views/crafted/pages/consultant/Pricing.vue"),
          },
          {
            path: "special-pricings",
            name: "consultant-special-pricings",
            component: () =>
              import("@/views/crafted/pages/consultant/SpecialPricing.vue"),
          },
          {
            path: "profile_photo",
            name: "consultant-photo",
            component: () =>
              import("@/views/crafted/pages/consultant/ProfilePhoto.vue"),
          },
          
         
        ],
      },

      {
        path: "/consultants/:consultant_type",
        name: "consultants",
        component: () => import("@/views/manage/ConsultantList.vue"),
      },

      {
        path: "/calls",
        name: "calls",
        component: () => import("@/views/reports/Calls.vue"),
      },

      {
        path: "/sales",
        name: "sales",
        component: () => import("@/views/reports/Sales.vue"),
      },

    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
